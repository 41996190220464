/**
 * Handler to trigger callbacks once the browser is ready for them.
 *
 * You can keep adding references using onReady() even after the page is loaded. In that case they will be
 * run at once.
 *
 * @example
 * import { onReady } from './utils/events/onReady';
 *
 * onReady(yourFunctionHere);
 *
 */

let functionReferences = [];

// Set the initial readyState based on the browser's current state. If the script has been loaded
// asynchronously, the DOM might be ready for us already, in which case there's no reason to delay
// any further processing. The following will evaluate as true if the DOM is ready, or the page is
// complete.
let readyState = document.readyState === 'interactive' || document.readyState === 'complete';

// Defines whether or not the window.onReady event has been bound, so we won't do it twice. That
// would just be stupid.
let readyEventBound = false;

/**
 * Run the given array of callback functions.
 *
 * @private
 * @param {Array} funcArray
 */
function runFunctionArray(funcArray) {
    funcArray.forEach(funcRef => funcRef());
}

/**
 * Empty the callback arrays
 *
 * @private
 */
function emptyCallbackArrays() {
    // Keep iterating through the function references until there are none left.
    while (functionReferences.length) {
        // Set up a temporary array that mirrors the list of callbacks, and empty the real one.
        const tempArray = functionReferences.slice(0);
        functionReferences = [];

        // Run the callbacks. The callbacks themselves may set up more callbacks, which
        // is why we keep looping the array until we're done.
        runFunctionArray(tempArray);
    }

    // At this point we'll assume we're ready for anything!
    readyState = true;
}

/**
 * Make sure the "ready"-event is set.
 *
 * @private
 */
function bindReadyEvent() {
    if (!readyEventBound) {
        if (document.readyState === 'loading') {
            // loading yet, wait for the event
            document.addEventListener('DOMContentLoaded', emptyCallbackArrays);
        } else {
            // DOM is ready!
            emptyCallbackArrays();
        }

        readyEventBound = true;
    }
}

/**
 * Register a function to run when the page is ready.
 *
 * @param {Function} functionReference - The function you want to run.
 */
export function onReady(functionReference) {
    if (typeof functionReference === 'function') {
        if (readyState) {
            functionReference();
        } else {
            bindReadyEvent();

            functionReferences.push(functionReference);
        }
    }
}
