import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
//import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        //disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            nav.classList.add('going-up');
        }

    } else {
        //enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }
}
