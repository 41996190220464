// Make Class to set inline width on every .category__text

import { onWindowResize } from '../utils/windowResize';

export class Category {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */

    constructor(container) {
        this.container = container;

        this.setTextWidths();
        onWindowResize(this.setTextWidths.bind(this));
    }

    setTextWidths() {
        const textElems = this.container.querySelectorAll('.category__text-col');

        if (textElems.length) {
            for (let i = 0; i < textElems.length; i++) {
                const textElem = textElems[i];
                textElem.removeAttribute('style');
                textElem.style.width = `${textElem.offsetWidth}px`;
            }
        }
    }
}

export function setupCategories(selector = '[data-action*="categories"]') {
    const containers = document.body.querySelectorAll(selector);

    for (let i = 0; i < containers.length; i++) {
        void new Category(containers[i]);
    }
}
