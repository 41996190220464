import { getElementScroll } from './elementProperties';
import { scrollLocked } from './scrollLock';
import { onScroll } from './scroll';
import { vh } from '../utils/helpers';

/**
 *
 * @param {HTMLElement} element - element to add sticky class to
 * @param {string} className - sticky class name to add on scroll
 * @param {number} scrollInPixels - number of pixels before activating scroll
 * @param {string} goingUpClass - class added when scrolling up
 */
export function stickyNavOnScroll(element, className = 'nav--sticky', scrollInPixels = 30, goingUpClass = 'nav--going-up') {
    let scrollTimer;
    let lastScrollPosition;

    const scrollHandler = () => {

        clearTimeout(scrollTimer);

        if (!element.classList.contains('nav--open')) {
            scrollTimer = setTimeout(() => {
                const windowScroll = getElementScroll();

                if (windowScroll.top > scrollInPixels) {
                    element.classList.add(className);

                    if (lastScrollPosition > windowScroll.top && windowScroll.top > vh * 100) {
                        element.classList.add(goingUpClass);
                    } else {
                        element.classList.remove(goingUpClass);
                    }

                    lastScrollPosition = windowScroll.top;
                } else {
                    element.classList.remove(className);
                    element.classList.remove(goingUpClass);
                }
            }, 20);
        }

    };

    onScroll(scrollHandler, true);
}
